import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar'
function ContactPage() {
    return (
        <div>
            <Navbar></Navbar>
           <Contact></Contact>
           <Footer></Footer>
        </div>
    );
}

export default ContactPage;